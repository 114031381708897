body {
    /* background-image: url(../img/pipa.png); */
    /*background-color: black;*/
    /*background-size: cover;*/
}

.services-h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;

    padding: 0 2%;
}

.services-h3 h3 {
    /*padding: 1% 0 1%;*/
    color: white;
    text-align: center;
    /*margin: 0;*/
}

.services-h3 h5 {
    padding: 4% 0 1%;
    color: white;
    text-align: center;
    margin: 0;
}

.service {
    /* background-image: url(../img/pipa.png); */
    background-color: black;
    background-position: 50% 23%;
    background-repeat: no-repeat;
    margin-top: 0%;

}

@media screen and (min-width: 700px){
    .service {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2rem 0;
    }

    .service div {
        /* margin: 0rem 1rem 0rem 1rem; */
        padding: 2rem;
        padding-left: 6rem;
    }

    .service .none {
        display: block;
    }

    .about h4 {
        font-size: 24px;
        margin: 0 1rem;
        color: white;
    }

    .about .aboutbg {
        padding: 7rem;
        display: flex;
        flex-direction: column;
        gap: 28px;

    }
}

@media screen and (max-width: 700px){
    .service {
        display: grid;
        grid-template-columns: 1fr ;
        padding: 1rem 0;
    }

    .service div {
        /* margin: 0rem 1rem 0rem 1rem; */
        padding: 1rem;
        padding-left: 2rem;
    }

    .service .none {
        display: none;
    }

    .about h4 {
        font-size: 18px;
        margin: 0 1rem;
        color: white;
    }

    .about .aboutbg {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        gap: 28px;

    }
}



.service img {
    filter: invert();
    width: 50px;
    height: 50px;
}

.service .exist {
    /* border-style: solid; */
    /* color: white; */
    /* background-color: white; */
    /* border-radius: 1rem; */
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.service .exist h4 {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about {
    /* backdrop-filter: sepia(90%);; */
    /* -webkit-backdrop-filter: blur(10px); */
    background-image: url(../assets/services/image2.png);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    /* height: 600px; */

}





/*footer .footer-bar .using-service  {*/
/*    background-color: white;*/
/*    color: black;*/
/*    border: 1px solid #f7654a;*/
/*}*/