@media screen and (min-width: 1200px) {

    .pictures img {
        object-fit: cover;
        object-position: center;
        margin-bottom: 1em;
        width: 22rem;
        height: 22rem;
    }
    .carousel-pictures {
        width: 22rem;
        height: 22rem;
    }

    article .top-separate h3 {
        text-align: center;
        /* margin: 4%; */
    }
    .ReactModal__Overlay  {
        /*position: absolute;*/
        max-height: 95%;
        top: 5rem!important;
        /*overflow: hidden;*/
    }

    .pictures {
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-type: x mandatory;
        display: flex;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        -webkit-column-gap: 1em;
        -moz-column-gap: 1em;
        column-gap: 1em;
        /* margin-bottom: -1em; */
        transition: left 1s;
        margin: 0 10px;
        gap: 10px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {

    .pictures img {
        object-fit: cover;
        object-position: center;
        margin-bottom: 1em;
        width: 16rem;
        height: 16rem;
    }
    .carousel-pictures {
        width: 16rem;
        height: 16rem;
    }

    article .top-separate h3 {
        text-align: center;
        /* margin: 4%; */
    }
    .ReactModal__Overlay  {
        /*position: absolute;*/
        max-height: 95%;
        top: 4rem!important;
        /*overflow: hidden;*/
    }

    .pictures {
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-type: x mandatory;
        display: flex;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        -webkit-column-gap: 1em;
        -moz-column-gap: 1em;
        column-gap: 1em;
        /* margin-bottom: -1em; */
        transition: left 1s;
        margin: 0 10px;
        gap: 10px;
    }
}

@media screen and (max-width: 900px) {

    .pictures img {
        object-fit: cover;
        object-position: center;
        max-width: 48%;
        /*margin-bottom: 1em;*/
        /*width: 8rem;*/
        /*height: 8rem;*/
    }
    .carousel-pictures {
        width: 12rem;
        height: 12rem;
    }

    .next, .prev {
        display: none;
    }

    article .top-separate h3 {
        text-align: center;
        margin: 4%;
    }
    .ReactModal__Overlay  {
        /*position: absolute;*/
        max-height: 95%;
        top: 4rem!important;
        /*overflow: hidden;*/
    }

    .pictures {
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-type: x mandatory;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        /*-webkit-column-count: 3;*/
        /*-moz-column-count: 3;*/
        /*column-count: 3;*/
        -webkit-column-gap: 1em;
        -moz-column-gap: 1em;
        column-gap: 1em;
        /* margin-bottom: -1em; */
        transition: left 1s;
        margin: 0 10px;
        gap: 10px;
    }
}

.carousel {
    display: flex;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    column-gap: 1em;
    /* margin-bottom: -1em; */
}



.pictures {
    overflow: scroll;

    /* position: relative; */
    /* min-height: 100vh; */
    /* background: #ddd; */
}

.pictures::-webkit-scrollbar{
    width: 0;
    display: none;
}

.pictures::-webkit-scrollbar-track{
    background: black;
    border-radius: 0;
}

.pictures::-Webkit-scrollbar-thumb{
    background: white;
    border-radius: 0;
}
.pictures div {
    scroll-snap-align: center;
    display: contents;
}

.photo-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.photo-list div {
    display: contents;
}

.photo-list img {
    max-width: 33%;
    object-fit: cover;
}
