.about {
    background-color: black;

}


.about-h3 {
    background-color: black;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 1500px) {

    .about-con {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .about-con-alt {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: row-reverse;
        background-color: white;
        /* background-image: url(../img/BgAbout4.jpg); */
        background-size: cover;

    }

    .about-img img, .about-img-alt img, .about-img-max img {
        max-width: 75%;
        object-fit: cover;
        padding: 0 2rem;
    }

    .about-img-min {
        display: none;
    }

    .about-img-max {
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .about-text, .about-text-alt {
        padding: 5rem 0;
        max-width: 50%;
    }

    .about-text h4, .about-text li {
        font-size: x-large;
        color: white;
        padding: 0 10%;
    }

    .about-text-alt h4 {
        color: black;
        font-size: x-large;
        font-weight: bold;
        padding: 0 10%;
    }

}



@media screen and (min-width: 900px) and (max-width:1500px) {
    .about-con {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .about-con-alt {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: row-reverse;
        background-color: white;
        /* background-image: url(../img/BgAbout4.jpg); */
        background-size: cover;

    }

    .about-img img, .about-img-alt img, .about-img-max img {
        max-width: 90%;
        object-fit: cover;
        padding: 0 2rem;
    }

    .about-img-min {
        display: none;
    }

    .about-img-max {
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .about-text, .about-text-alt {
        padding: 5rem 0;
        max-width: 50%;
    }

    .about-text h4, .about-text li {
        font-size: x-large;
        color: white;
        padding: 0 10%;
    }

    .about-text-alt h4 {
        color: black;
        font-size: x-large;
        font-weight: bold;
        padding: 0 10%;
    }

}



@media screen and (max-width: 900px) {
    .about-con {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: column;
        padding: 1rem 0;
    }

    .about-con-alt {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: column;
        background-color: white;
        /* background-image: url(../img/BgAbout4.jpg); */
        background-size: cover;
        padding: 1rem 0;
        background-position: center;
    }

    .about-img .max {
        display: none;
    }

    .about-img img, .about-img-alt img, .about-img-min img {
        max-width: 90%;
        object-fit: cover;
        padding: 0 2rem;
    }

    .about-img-max {
        display: none;
    }

    .about-img-min {
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .about-text, .about-text-alt  {
        padding: 0;
        /* max-width: 50%;    */
    }

    .about-text h4, .about-text li {
        font-size: large;
        color: white;
        padding: 0 10%;
    }

    .about-text-alt h4 {
        color: black;
        font-size: large;
        font-weight: bold;
        padding: 0 10%;
    }

}

.about-logo {
    text-align: center;
}

.about-logo img {
    z-index: -1;
    max-width: 50%;
    /* filter: invert(99%) sepia(5%) saturate(366%) hue-rotate(214deg) brightness(120%) contrast(100%); */
}

.about-img, .about-img-alt {
    max-width: 100%;
    display: flex;
    justify-content: center;

}

.about-text a {
    color: #f7654a;
}

.about-text a:hover,
.about-text a:active{
    color: white;
}


/*footer .footer-bar .using-about {*/
/*    background-color: white;*/
/*    color: black;*/
/*    border: 1px solid #f7654a;*/
/*}*/