@media screen and (min-width: 1250px) {
    .contact {
        /*padding: 0 20%;*/
    }
    .contact-form {
        background: black;
        color: white;
        width: 100%;
        padding: 2% 35%;
    }
    .contact-form input {
        padding: 3%;
        border-radius: 1rem;
        background-color: white;
        border: 1px solid black;
        font-family: "Roboto";
        width: 100%;
        color: black;
    }
}


@media screen
and (min-width: 615px)
and (max-width: 1250px){
    .contact {
        padding: 0 20%;
    }
    .contact-form {
        background: black;
        color: white;
        width: 100%;
        padding: 0 20%;
    }
    .contact-form input {
        padding: 3%;
        border-radius: 1rem;
        background-color: white;
        border: 1px solid black;
        font-family: "Roboto";
        width: 100%;
        color: black;
    }
}

@media screen and (max-width: 615px) {
    .contact {
        padding: 5%;
    }
    .contact-form {
        background: black;
        color: white;
        width: 100%;
        padding: 0;
    }
    .contact-form input {
        padding: 5%;
        border-radius: 1rem;
        background-color: white;
        border: 1px solid black;
        font-family: "Roboto";
        width: 100%;
        color: black;
    }
}

.contact div {
    background-color: black;
    /* border-radius: 15px; */
    /* margin: 10px 100px 10px 100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /*padding: 0% 11% 7%;*/
}



.contact-form div {
    display: flex;
    flex-direction: column;
    /*padding: 0 20%;*/
}



.contact-form textarea {
    padding: 3%;
    border-radius: 1rem;
    background-color: white;
    border: 1px solid black;
    font-family: "Roboto";
    width: 100%;
    color: black;
}

.contact-form contact-button {
    margin-top: 5%;
    padding: 5% 5%;
    border-radius: 1rem;
    background-color: black;
    border: 1px solid #f7654a;
    font-family: "Roboto";
    width: 100%;
    color: white;
}

.contact-form h4 {
    color: white;
    margin: 0;
    font-size: 75%;
    margin: 2% 0;
}

