* {
    margin: 0;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-track{
    background: black;
    border-radius: 50px;
}

::-Webkit-scrollbar-thumb{
    background: white;
    border-radius:50px;
}

.cont {
    padding: 0 20%;
}

.bg-black {
    background-color: black;
}

h3 {
    font-family: 'Oswald', sans-serif;
}

h4, li {
    font-family: 'Source Sans Pro', sans-serif;
    /* font-weight: bold; */
}







.contact-button {
    margin-top: 5%;
    padding: 5% 5%;
    border-radius: 1rem;
    background-color:  black;
    border: 1px solid  #f7654a;
    font-family: "Roboto";
    width: 100%;
    color: white;
}

.contact-button:hover,
.contact-button:active {
    background-color: #f7654a;
    color: black;
}

.super-hr {
    width: 15%;
    /*justify-content: left;*/
    margin: 1%;
    background-color: #f7654a;
    color: #f7654a;
    opacity: 1;
}


@media screen and (min-width: 1250px) {}


@media screen
and (min-width: 615px)
and (max-width: 1250px){}

@media screen and (max-width: 615px) {}
